@font-face { font-family: "SFPro"; font-weight: 400; font-style: normal; src: url("../../assets/fonts/SFProText-Regular.ttf"); }
@font-face { font-family: "SFPro"; font-weight: 500; font-style: normal; src: url("../../assets/fonts/SFProText-Medium.ttf"); }
@font-face { font-family: "SFProD"; font-weight: 400; font-style: normal; src: url("../../assets/fonts/SFProDisplay-Regular.ttf"); }
@font-face { font-family: "TTBold"; font-weight: 600; font-style: normal; src: url("../../assets/fonts/TTCommons-Bold.ttf"); }

body[scheme="space_gray"],
body[scheme="client_dark"] {
  .app {
    --custom-grey: var(--media_overlay_button_background);
    --custom-dark-grey: var(--media_overlay_button_background);
  }
}

.app {
  --custom-orange: #ff4c00;
  --custom-grey: #3b393f;
  --custom-green: #0e920e;
  --custom-dark-grey: #3b393f;


  .Tabbar {
    box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.12);

    &.hide {
      display: none
    }
  }


  .TabbarItem--selected {
    color: var(--custom-orange);
  }

  .slider-cell {

    &--discounted {

      .Cell {
        overflow: visible;

        &__in {
          overflow: visible;
        }
      }
    }
  }

  .Cell--discounted {
    position: absolute;
    top: 5px;
    right: -5px;
    z-index: 1;

    padding: 1px 4px 0 4px;

    font-family: 'SfPro', Roboto, sans-serif;
    font-size: 9.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.07px;
    color: var(--white);

    background-color: var(--custom-orange);
    border-radius: 4px;
  }

  .btn-mixin {
    font-family: 'SfPro', Roboto, sans-serif;
    color: var(--custom-grey);

    background-color: transparent;
    border: 1px solid var(--custom-grey);

    &--orange {
      height: 30px;
      border-color: var(--custom-orange);
    }
  }

  .full-price {
    font-family: 'SfPro', Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.13px;
    text-decoration: line-through;
    color: #aeb7c2;
  }

  .discount-price {
    margin-left: 6px;

    font-family: 'SfPro', Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.15px;
    text-decoration: none;
    color: var(--custom-orange);
  }

}

.PanelHeaderButton {
  color: var(--text_primary) !important;
}

iframe {
  position: fixed;

  width: 100%;
  height: calc(100vh - 60px);

  border: 0;
}

.ActionSheetItem--cancel {
  .ActionSheetItem__children {
    text-align: center;
    color: var(--accent)
  }
}

.ActionSheetItem__children {
  text-align: left;
  color: var(--text_primary)
}

.empty-search {
  margin-top: 25vh;
  cursor: default;
}

.Button--lvl-commerce {
  background-color: #0e920e !important;
}
